import { useState, useEffect } from "react";
import { Alert, Button, TextAreaInput, Select } from "@octano/global-ui";
import { useLeaveRequestForm } from "./hooks/useLeaveRequestForm";
import { NewLeaveProps } from "../type";

import { VacationFields } from "./VacationFields";
import { AdminDaysFields } from "./AdminDaysFields";
import { GroupLeaveFields } from "./GroupLeaveFields";

export const NewLeave: React.FC<NewLeaveProps> = ({
  authorizers,
  adminDays,
  leaveGroups,
  refresh,
}) => {
  const [authorizerType, setAuthorizerType] = useState<"JERARQUIA" | "PERSONA">(
    "JERARQUIA"
  );
  const hasPersonaAuthorizers = authorizers.some(
    (auth) => auth.TIPO_AUTORIZADOR === "PERSONA"
  );

  const hasGroupLeaves = leaveGroups && leaveGroups.length > 0;
  const requestTypeOptions = [
    { label: "Vacaciones", value: 0 },
    { label: "Días administrativos", value: 1 },
    ...(hasGroupLeaves ? [{ label: "Otros permisos", value: 2 }] : []),
  ];

  const {
    control,
    setValue,
    isVacation,
    isAdminDays,
    isGroupLeave,
    isFullDay,
    isHalfDay,
    date_ini,
    workingDays,
    workingDaysError,
    authorizerEmailError,
    warningText,
    isLoading,
    disabledSubmit,
    onSubmit,
    modalClose,
  } = useLeaveRequestForm({ authorizers, refresh, leaveGroups });

  const getFirstAuthorizerByType = (type: "JERARQUIA" | "PERSONA") => {
    return authorizers.find(
      (auth) => auth.TIPO_AUTORIZADOR === type && auth.ORDEN !== "SUBROGANTE"
    );
  };

  useEffect(() => {
    const firstAuthorizer = getFirstAuthorizerByType(authorizerType);
    if (firstAuthorizer) {
      setValue("authorizer", {
        label: `${firstAuthorizer.NOMBRE_AUT} - ${firstAuthorizer.CORREO_AUT}`,
        value: firstAuthorizer.CORREO_AUT,
      });
    }
  }, [authorizerType, setValue]);

  const filteredAuthorizers = authorizers.filter(
    (auth) =>
      auth.TIPO_AUTORIZADOR === authorizerType && auth.ORDEN !== "SUBROGANTE"
  );

  const bossEmail =
    filteredAuthorizers.find(
      (auth) =>
        auth.TIPO_AUTORIZADOR === "JERARQUIA" && auth.ORDEN !== "SUBROGANTE"
    )?.CORREO_AUT || "";

  const authorizerTypeOptions = [
    {
      label: bossEmail ? `Jefatura - ${bossEmail}` : "Jefatura",
      value: "JERARQUIA",
    },
    ...(hasPersonaAuthorizers
      ? [{ label: "Autorizadores", value: "PERSONA" }]
      : []),
  ];

  return (
    <div className="px-1">
      <h4 className="py-3 fs-22 text-dark text-center">
        Nueva solicitud de permiso
      </h4>
      <span className="modal-subtitle">
        La solicitud será enviada para su posterior aprobación.
      </span>
      <form onSubmit={onSubmit} className="container">
        <div className="row">
          <div className="col p-0">
            <Select
              name="requestType"
              label="TIPO DE PERMISO"
              control={control}
              options={requestTypeOptions}
              rules={{ required: true }}
            />
          </div>
        </div>

        <div className="row">
          <div className="col p-0">
            <Select
              name="authorizerType"
              label="TIPO DE AUTORIZADOR"
              value={authorizerTypeOptions.find(
                (opt) => opt.value === authorizerType
              )}
              onChange={(option) =>
                setAuthorizerType(option.value as "JERARQUIA" | "PERSONA")
              }
              options={authorizerTypeOptions}
              disabled={!hasPersonaAuthorizers}
            />
          </div>
        </div>

        {authorizerType === "PERSONA" && (
          <div className="row">
            <div className="col p-0">
              <Select
                name="authorizer"
                label="AUTORIZADOR DE LA SOLICITUD"
                control={control}
                options={filteredAuthorizers.map((auth) => ({
                  label: `${auth.NOMBRE_AUT} - ${auth.CORREO_AUT}`,
                  value: auth.CORREO_AUT,
                }))}
                rules={{ required: true }}
                errorText={authorizerEmailError}
              />
            </div>
          </div>
        )}

        {/* VACACIONES */}
        {isVacation && (
          <VacationFields
            control={control}
            date_ini={date_ini}
            workingDays={workingDays}
            workingDaysError={workingDaysError}
          />
        )}

        {/* DÍAS ADMINISTRATIVOS */}
        {isAdminDays && (
          <AdminDaysFields
            control={control}
            adminDays={adminDays}
            date_ini={date_ini}
            isFullDay={isFullDay}
            isHalfDay={isHalfDay}
          />
        )}

        {/* OTROS PERMISOS */}
        {isGroupLeave && leaveGroups && leaveGroups.length > 0 && (
          <GroupLeaveFields control={control} leaveGroups={leaveGroups} />
        )}

        <div className="row">
          <div className="col p-0">
            <TextAreaInput label="Motivo" name="motive" control={control} />
          </div>
        </div>

        {warningText && (
          <div className="row mt-4">
            <div className="col p-0">
              <Alert
                color="info"
                icon="information"
                size="lg"
                text={warningText}
              />
            </div>
          </div>
        )}
      </form>

      <div className="modal-action pt-3">
        <Button
          text="Cancelar"
          outlined
          size="lg"
          fullwidth
          className="modal-action-btn"
          onClick={modalClose}
        />
        <Button
          text="Enviar SOLICITUD"
          size="lg"
          fullwidth
          onClick={onSubmit}
          className="modal-action-btn"
          loading={isLoading}
          disabled={disabledSubmit}
        />
      </div>
    </div>
  );
};
