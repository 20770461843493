import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { addToast } from "@octano/global-ui";
import { useModal } from "hooks";
import dayjs from "dayjs";
import { newLeave, CalculateWorkingDays, CalculateEndDate } from "../../api";
import { LeaveFormData, UseLeaveRequestFormProps } from "../../type";

export const useLeaveRequestForm = ({
  authorizers,
  refresh,
  leaveGroups,
}: UseLeaveRequestFormProps) => {
  const { modalClose } = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [workingDays, setWorkingDays] = useState<string | undefined>(undefined);
  const [workingDaysError, setWorkingDaysError] = useState<string | undefined>(
    undefined
  );
  const [authorizerEmailError, setAuthorizerEmailError] = useState<
    string | undefined
  >(undefined);
  const [disabledSubmit, setDisabledSubmit] = useState<boolean>(true);
  const firstAuthorizer = authorizers.find(
    (authorizer) => authorizer.TIPO_AUTORIZADOR === "JERARQUIA"
  );

  const { control, handleSubmit, watch, setValue } = useForm<LeaveFormData>({
    defaultValues: {
      date_ini: null,
      date_fin: null,
      motive: "",
      requestType: { label: null, value: null },
      requestFormat: { label: null, value: null },
      halfDaySchedule: { label: null, value: null },
      daysAmount: null,
      authorizer: {
        label: firstAuthorizer
          ? `${firstAuthorizer.NOMBRE_AUT} - ${firstAuthorizer.CORREO_AUT}`
          : null,
        value: firstAuthorizer?.CORREO_AUT || null,
      },
      selectedGroup: null,
    },
  });

  const [
    date_ini,
    date_fin,
    authorizerSelected,
    requestType,
    requestFormat,
    halfDaySchedule,
    daysAmount,
    selectedGroup,
  ] = watch([
    "date_ini",
    "date_fin",
    "authorizer",
    "requestType",
    "requestFormat",
    "halfDaySchedule",
    "daysAmount",
    "selectedGroup",
  ]);

  const isVacation =
    requestType?.label === "Vacaciones" || requestType?.value === 0;
  const isAdminDays =
    requestType?.label === "Días administrativos" || requestType?.value === 1;
  const isGroupLeave =
    requestType?.label === "Otros permisos" || requestType?.value === 2;

  /**
   * Calcula días hábiles (solo para vacaciones).
   */
  const calculateWorkingDays = async (dIni: Date | null, dFin: Date | null) => {
    if (dIni instanceof Date && dFin instanceof Date && dIni <= dFin) {
      setWorkingDays("...");
      setWorkingDaysError(undefined);
      try {
        const response = await CalculateWorkingDays({
          date_ini: dayjs(dIni).format("YYYY-MM-DD"),
          date_fin: dayjs(dFin).format("YYYY-MM-DD"),
        });
        setWorkingDays(response.data?.p_cantidad?.toString() || undefined);

        if (response.data?.p_det_error) {
          setWorkingDaysError(response.data?.p_det_error);
        }
      } catch {
        setWorkingDays(undefined);
      }
    } else {
      setWorkingDays("");
    }
  };

  /**
   * Calcula fecha de término (solo para permisos especiales).
   */
  const calculateEndDate = async (
    dIni: Date | null,
    days: number,
    type: string
  ) => {
    if (dIni instanceof Date && days > 0) {
      try {
        const response = await CalculateEndDate({
          date_ini: dayjs(dIni).format("YYYY-MM-DD"),
          days,
          type,
        });
        const endDate = response.data?.p_fecha_termino;

        if (endDate) {
          const [dd, mm, yyyy] = endDate.split("/");
          const isoFormat = `${yyyy}-${mm}-${dd}`;
          setValue("date_fin", new Date(isoFormat));
        }
      } catch {
        setValue("date_fin", null);
      }
    } else {
      setValue("date_fin", null);
    }
  };

  /**
   * Valida que el autorizador tenga correo
   */
  const validateAuthorizerEmail = (
    authSel: { value: string | null } | null
  ) => {
    if (!authSel?.value) {
      setAuthorizerEmailError(
        "El responsable asignado no posee correo institucional. Para continuar, contacta a la administración."
      );
    } else {
      setAuthorizerEmailError(undefined);
    }
  };

  /**
   * Mostrar un warning si la fecha de inicio es pasada
   */
  const getWarningText = (dIni: Date | null): string | undefined => {
    if (dIni && dayjs().isAfter(dIni, "day")) {
      return "Estás solicitando una fecha pasada. Requieres autorización escrita de tu jefatura.";
    }
    return undefined;
  };

  useEffect(() => {
    setValue("date_fin", null);
  }, [date_ini, setValue]);

  useEffect(() => {
    if (isVacation) {
      calculateWorkingDays(date_ini, date_fin);
    } else {
      setWorkingDays("");
      setWorkingDaysError(undefined);
    }
  }, [date_ini, date_fin, isVacation]);

  useEffect(() => {
    validateAuthorizerEmail(authorizerSelected);
  }, [authorizerSelected]);

  useEffect(() => {
    if (isAdminDays && requestFormat?.value === "half") {
      setValue("daysAmount", 1);
    }
  }, [isAdminDays, requestFormat, setValue]);

  useEffect(() => {
    if (isVacation) {
      const noWorkingDays = !workingDays || workingDays === "0";
      setDisabledSubmit(
        !!workingDaysError || !!authorizerEmailError || noWorkingDays
      );
    } else if (isAdminDays) {
      const invalidDays = !daysAmount || isNaN(daysAmount) || daysAmount < 1;
      setDisabledSubmit(invalidDays || !!workingDaysError);
    } else if (isGroupLeave) {
      const noSelectedGroup = !selectedGroup?.value;
      setDisabledSubmit(noSelectedGroup || !!authorizerEmailError);
    } else {
      setDisabledSubmit(true);
    }
  }, [
    isVacation,
    isAdminDays,
    isGroupLeave,
    workingDays,
    workingDaysError,
    authorizerEmailError,
    daysAmount,
    selectedGroup,
  ]);

  // Lógica específica para “Otros permisos”. Determina si es “Abierto” o “Por Evento”
  useEffect(() => {
    if (!isGroupLeave || !leaveGroups) return;

    const grp = leaveGroups.find((g) => g.grupo === selectedGroup?.value);
    if (!grp) return;

    const isEvento = grp.tipo_aplicacion === "EV";

    const maxDias = grp.dias ? parseInt(grp.dias, 10) : 0;
    if (isEvento) {
      setValue("daysAmount", maxDias);
      calculateEndDate(date_ini, maxDias, grp.tipo_dia || "HA");
    }
  }, [isGroupLeave, selectedGroup, leaveGroups, setValue, date_ini]);

  // Calcular endDate para “Otros permisos”
  useEffect(() => {
    if (isGroupLeave && selectedGroup) {
      const grp = leaveGroups?.find((g) => g.grupo === selectedGroup?.value);
      if (grp) {
        calculateEndDate(date_ini, daysAmount || 0, grp.tipo_dia || "HA");
      }
    }
  }, [isGroupLeave, selectedGroup, date_ini, daysAmount]);

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);

    try {
      const chosenAuthorizer = authorizers.find(
        (auth) => auth.CORREO_AUT === data.authorizer.value
      );

      let finalRequestType = "";
      let finalDaysAmount = 0;
      let isFullDay = false;
      let classNumber = "";
      let groupNumber = "";

      if (isVacation) {
        finalRequestType = "Vacaciones";
        isFullDay = true;
        finalDaysAmount = parseInt(workingDays || "0");
      } else if (isAdminDays) {
        isFullDay = requestFormat?.value === "full";
        finalRequestType = "Días administrativos";
        finalDaysAmount = daysAmount ?? 0;
      } else if (isGroupLeave && leaveGroups) {
        finalRequestType = "Otros permisos";
        isFullDay = true;
        const grp = leaveGroups.find(
          (g) => g.grupo === data.selectedGroup?.value
        );
        if (grp) {
          finalDaysAmount =
            grp.tipo_aplicacion === "EV"
              ? parseInt(grp.dias || "0")
              : daysAmount ?? 0;
          classNumber = grp.clase;
          groupNumber = grp.grupo;
        }
      }

      const reqParams = {
        startDate: dayjs(data.date_ini).format("YYYY-MM-DD"),
        endDate: isAdminDays
          ? undefined
          : dayjs(data.date_fin).format("YYYY-MM-DD"),
        isFullDay: isFullDay,
        isMorning: !isAdminDays ? null : halfDaySchedule.value === "morning",
        quantity: finalDaysAmount,
        requestType: finalRequestType,
        comment: data.motive,
        authorizerRol: chosenAuthorizer?.AUTORIZADOR || "",
        authorizerEmail: chosenAuthorizer?.CORREO_AUT || "",
        authorizerName: chosenAuthorizer?.NOMBRE_AUT || "",
        classNumber: classNumber,
        groupNumber: groupNumber,
      };

      // Llamada a la API
      const response = await newLeave(reqParams);

      if (!response.error) {
        addToast({
          text: "Solicitud enviada con éxito",
          autoClose: 5000,
          icon: "success",
          color: "success",
        });
        await refresh();
        modalClose();
      } else {
        throw new Error("Error al enviar la solicitud");
      }
    } catch (error) {
      addToast({
        text: "Error al enviar la solicitud",
        autoClose: 5000,
        icon: "error",
        color: "danger",
      });
    } finally {
      setIsLoading(false);
    }
  });

  return {
    control,
    setValue,
    isVacation,
    isAdminDays,
    isGroupLeave,
    isFullDay: requestFormat?.value === "full",
    isHalfDay: requestFormat?.value === "half",
    isLoading,
    disabledSubmit,
    date_ini,
    workingDays,
    workingDaysError,
    authorizerEmailError,
    warningText: getWarningText(date_ini),
    selectedGroup,
    onSubmit,
    modalClose,
  };
};
