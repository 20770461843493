import { ReactElement, useCallback } from "react";
import {
  AuthenticationError,
  AxiosResult,
  AxiosResultDefaultError,
} from "../../../../api/request2";
import { createFetchContext } from "components/context/FetchContextFactory";
import Loading from "components/Loading";
import DisplayError from "components/info/DisplayError";
import {
  Authorizers,
  GroupLeave,
  Holidays,
  Leaves,
  LeavesResponse,
  RegisteredLeave,
} from "../../type";
import { getData } from "views/Leaves/api";

interface BaseLoaderProps {
  children: (data: {
    holidays: Holidays;
    leaves: Leaves;
    registereds: RegisteredLeave[];
    requests: [];
    authorizers: Authorizers[];
    leaveGroups: GroupLeave[];
    refresh: () => Promise<void>;
  }) => ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  undefined,
  LeavesResponse,
  AuthenticationError | AxiosResultDefaultError
>();

export function useLeavesLoader() {
  return useFetch();
}

export const LeavesLoaderConsumer = FetchConsumer;

export default function LeavesLoader({ children }: BaseLoaderProps) {
  const request = useCallback(async (): Promise<
    AxiosResult<LeavesResponse, AxiosResultDefaultError>
  > => {
    return getData();
  }, []);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ loading, data, error, refresh }) => {
          if (loading) {
            return <Loading />;
          } else if (error) {
            return (
              <DisplayError typeError={error.code} retryAction={refresh} />
            );
          } else if (data) {
            return children({
              holidays: {
                previousPeriodAccumulatedDays: data.holidaysStatus.p_acumulados,
                currentPeriodAccumulatedDays: data.holidaysStatus.p_periodo,
                progressiveDays: data.holidaysStatus.p_progresivo,
                interruptedDays: data.holidaysStatus.p_interrumpidos,
                currentPeriodUsedDays: data.holidaysStatus.p_tomados_periodo,
                totalAvailableDays: data.holidaysStatus.p_disponibles,
                error: data.holidaysStatus.p_cod_error,
              },
              leaves: {
                totalAvailableDays: data.permissionsStatus.p_disponibles,
                error: data.permissionsStatus.p_cod_error,
              },
              registereds: data.registereds,
              requests: data.requests,
              authorizers: data.authorizers,
              leaveGroups: data.leaveGroups,
              refresh,
            });
          } else {
            return null;
          }
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
