import { Table } from "@octano/global-ui";
import { ColumnTable } from "@octano/global-ui/dist/components/Table/types/TableTypes";
import { CellFormat } from "components/containers/CellFormat";
import { ErrorTableData } from "components/ErrorTableData";
import dayjs from "dayjs";
import { usePagination } from "hooks/usePagination";

const columns: ColumnTable[] = [
  {
    columnName: "type",
    headerText: <CellFormat>Tipo de solicitud</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: "duration",
    headerText: <CellFormat>Duración</CellFormat>,
    cellFormat: ({ value }) => (
      <CellFormat>{value > 0 ? `${value} días` : value}</CellFormat>
    ),
  },
  {
    columnName: "availableDays",
    headerText: <CellFormat>Días hábiles</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
  {
    columnName: "requestDate",
    headerText: <CellFormat>Fecha de solicitud</CellFormat>,
    cellFormat: ({ value }) => (
      <CellFormat>{value ? dayjs(value).format("DD/MM/YYYY") : ""}</CellFormat>
    ),
  },
  {
    columnName: "startDate",
    headerText: <CellFormat>Fecha de inicio</CellFormat>,
    cellFormat: ({ value }) => (
      <CellFormat>{dayjs(value).format("DD/MM/YYYY")}</CellFormat>
    ),
  },
  {
    columnName: "endDate",
    headerText: <CellFormat>Fecha de término</CellFormat>,
    cellFormat: ({ value }) => (
      <CellFormat>{dayjs(value).format("DD/MM/YYYY")}</CellFormat>
    ),
  },
  {
    columnName: "comments",
    headerText: <CellFormat>Motivo</CellFormat>,
    cellFormat: ({ value }) => <CellFormat>{value}</CellFormat>,
  },
];

export interface LeavesHistoricTableProps {
  data: {
    type: string;
    duration: string;
    availableDays: string;
    requestDate: string;
    startDate: string;
    endDate: string;
    comments: string;
  }[];
}

export default function LeavesHistoricTable({
  data: dataProp,
}: LeavesHistoricTableProps) {
  const pagination = usePagination({ totalItems: dataProp.length, limit: 3 });

  const data = dataProp.slice(
    pagination.offset,
    pagination.offset + pagination.itemsPerPage
  );

  return (
    <Table
      columns={columns}
      noResultsText={<ErrorTableData />}
      data={data}
      striped={true}
      pagination={pagination}
    />
  );
}
