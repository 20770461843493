import { useState } from "react";
import * as Popper from "popper.js";
import { Tooltip as ReactstrapTooltip } from "reactstrap";

export interface TooltipProps {
  id: string;
  children: any;
  text: string;
  placement?: Popper.Placement;
}

const Tooltip = ({ id, children, text, placement = "right" }: TooltipProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span className="inline-block pr-2">
      <span id={id}>{children}</span>
      <ReactstrapTooltip
        placement={placement}
        isOpen={tooltipOpen}
        target={id}
        toggle={toggle}
      >
        {text}
      </ReactstrapTooltip>
    </span>
  );
};

export default Tooltip;
