// GroupLeaveFields.tsx
import React from "react";
import { Control, useWatch } from "react-hook-form";
import { Select, DateInput, TextInput } from "@octano/global-ui";

interface GroupLeave {
  grupo: string;
  descrip_grupo: string;
  dias: string | null;
  descrip_tipo_aplicacion?: string;
  permite_medio_dia?: string | null;
}

interface GroupLeaveFieldsProps {
  control: Control<any>;
  leaveGroups: GroupLeave[];
}

export const GroupLeaveFields: React.FC<GroupLeaveFieldsProps> = ({
  control,
  leaveGroups,
}) => {
  const selectedGroup = useWatch({ control, name: "selectedGroup" });
  const groupData = leaveGroups.find((g) => g.grupo === selectedGroup?.value);
  const isAbierto = groupData?.descrip_tipo_aplicacion === "Abierto";
  const isEvento = groupData?.descrip_tipo_aplicacion === "Por Evento";
  const maxDias = groupData?.dias ? parseInt(groupData.dias, 10) : 0;

  return (
    <>
      <div className="row">
        <div className="col p-0">
          <Select
            name="selectedGroup"
            label="Tipo"
            control={control}
            options={leaveGroups.map((g) => ({
              label: g.descrip_grupo,
              value: g.grupo,
            }))}
            rules={{ required: "Elige un tipo de permiso" }}
          />
        </div>
      </div>

      {groupData && (
        <div className="row p-0 input-gap">
          <div className="col-12 col-sm p-0 mr-2">
            <DateInput
              label="FECHA INICIO"
              name="date_ini"
              control={control}
              rules={{ required: "Este campo es requerido" }}
            />
          </div>

          <div className="col-12 col-sm p-0 ml-2">
            <TextInput
              label="Días"
              name="daysAmount"
              control={control}
              disabled={isEvento}
              rules={{
                required: "Este campo es requerido",
                min: { value: 1, message: "Debe ser mayor a 0" },
                validate: (value) => {
                  const numValue = Number(value);
                  if (isNaN(numValue)) {
                    return "La cantidad de días debe ser un número";
                  }
                  if (isEvento) {
                    return true;
                  }
                  // si es Abierto + maxDias=0 => sin límite
                  if (isAbierto && maxDias === 0) {
                    return true;
                  }
                  // si es Abierto + maxDias != 0 => topa en maxDias
                  if (isAbierto && maxDias > 0) {
                    if (numValue > maxDias) {
                      return `La cantidad de días solicitados supera los ${maxDias} permitidos.`;
                    }
                    return numValue > 0 || "Debe ser mayor a 0";
                  }
                  // Default (en caso de errores en los datos desde el ERP)
                  if (numValue > maxDias) {
                    return `La cantidad de días solicitados supera los ${maxDias} permitidos.`;
                  }
                  return numValue > 0 || "Debe ser mayor a 0";
                },
              }}
            />
          </div>

          <div className="col-12 col-sm p-0 mr-2">
            <DateInput
              label="FECHA TÉRMINO"
              name="date_fin"
              control={control}
              disabled
            />
          </div>
        </div>
      )}
    </>
  );
};
