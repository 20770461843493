import React from "react";
import { Control } from "react-hook-form";
import { Select, DateInput, TextInput } from "@octano/global-ui";

interface AdminDaysFieldsProps {
  control: Control<any>;
  adminDays: number;
  isFullDay: boolean;
  isHalfDay: boolean;
  date_ini: Date | null;
}

export const AdminDaysFields: React.FC<AdminDaysFieldsProps> = ({
  control,
  adminDays,
  isFullDay,
  isHalfDay,
}) => {
  return (
    <>
      <div className="row">
        <div className="col p-0">
          <Select
            name="requestFormat"
            label="FORMATO DE SOLICITUD"
            control={control}
            options={[
              { label: "Días completos", value: "full" },
              { label: "Medio día", value: "half" },
            ]}
            rules={{ required: true }}
          />
        </div>
      </div>

      {isFullDay && (
        <div className="row p-0 input-gap">
          <div className="col-12 col-sm p-0 mr-2">
            <DateInput
              label="FECHA INICIO"
              name="date_ini"
              control={control}
              rules={{ required: "Este campo es requerido" }}
            />
          </div>
          <div className="col-12 col-sm p-0 ml-2">
            <TextInput
              label="Cantidad de días"
              name="daysAmount"
              control={control}
              rules={{
                required: "Este campo es requerido",
                min: { value: 1, message: "Debe ser mayor a 0" },
                validate: (value) => {
                  const numValue = Number(value);
                  if (isNaN(numValue)) {
                    return "La cantidad de días debe ser un número";
                  }
                  if (numValue > adminDays) {
                    return `La cantidad de días solicitados supera los días administrativos disponibles (${adminDays}).`;
                  }
                  return numValue > 0;
                },
              }}
            />
          </div>
        </div>
      )}

      {isHalfDay && (
        <div className="row p-0 input-gap">
          <div className="col-12 col-sm p-0 mr-2">
            <DateInput
              label="FECHA"
              name="date_ini"
              control={control}
              rules={{ required: "Este campo es requerido" }}
            />
          </div>
          <div className="col-12 col-sm p-0 mr-2">
            <Select
              name="halfDaySchedule"
              label="Horario"
              control={control}
              options={[
                { label: "Mañana", value: "morning" },
                { label: "Tarde", value: "afternoon" },
              ]}
              rules={{ required: true }}
            />
          </div>
        </div>
      )}
    </>
  );
};
