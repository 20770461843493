import { Button, Icon } from "@octano/global-ui";

import { useModal } from "hooks";

import { CardLayout } from "components/CardLayout";
import { LeavesSummary } from "./parts/LeavesSummary";

import LeavesLoader from "./parts/Loaders/LeavesLoader";
import LeavesHistoricTable from "./parts/LeavesHistoricTable";
import LeavesRequestsTable from "./parts/LeavesRequestTable";
import { NewLeave } from "./parts/NewLeave";
import Tooltip from "./parts/Tooltip";

const Leaves: React.FC = () => {
  const { modalOpen } = useModal();

  return (
    <section id="solicitudes" className="pt-3">
      <CardLayout>
        <LeavesLoader>
          {(data) => (
            <div className="w-100">
              <div className="vacations-information">
                <div className="vacations-information-days">
                  <LeavesSummary
                    description="Días de vacaciones periodo actual"
                    tooltipTitle="Resumen de vacaciones disponibles"
                    boxId="tooltip-vacaciones"
                    holidays={data.holidays}
                    total={data.holidays.totalAvailableDays}
                    code_error={data.holidays.error}
                  />
                  <LeavesSummary
                    description="Días administrativos periodo actual"
                    tooltipTitle="Resumen de días administrativos disponibles"
                    boxId="tooltip-administrativos"
                    holidays={null}
                    total={data.leaves.totalAvailableDays}
                    code_error={data.leaves.error}
                  />
                </div>
                <div className="ml-auto">
                  {data.authorizers.length === 0 && (
                    <Tooltip
                      id="tooltip-vacaciones"
                      placement="bottom"
                      text="No tienes autorizadores asignados, por favor contacta a tu supervisor."
                    >
                      <Icon name="information" size={22} color="primary" />
                    </Tooltip>
                  )}
                  <Button
                    text="+ NUEVA SOLICITUD DE PERMISO"
                    size="sm"
                    className="vacations-information-btn"
                    disabled={data.authorizers.length === 0}
                    onClick={() =>
                      modalOpen(
                        <NewLeave
                          authorizers={data.authorizers}
                          adminDays={data.leaves.totalAvailableDays}
                          refresh={data.refresh}
                          leaveGroups={data.leaveGroups}
                        />,
                        "lg"
                      )
                    }
                  />
                </div>
              </div>
              <div className="mt-4">
                <h3 className="text-light fw-400 fs-20 mb-3">
                  Solicitudes de Permisos
                </h3>
                <LeavesRequestsTable
                  data={data.requests}
                  refresh={data.refresh}
                />
              </div>
              <div className="mt-4">
                <h3 className="text-light fw-400 fs-20 mb-3">
                  Histórico de Permisos
                </h3>
                <LeavesHistoricTable data={data.registereds} />
              </div>
            </div>
          )}
        </LeavesLoader>
      </CardLayout>
    </section>
  );
};

export default Leaves;
