import React from "react";
import { Control } from "react-hook-form";
import { DateInput, TextInput } from "@octano/global-ui";

interface VacationFieldsProps {
  control: Control<any>;
  date_ini: Date | null;
  workingDays: string | undefined;
  workingDaysError: string | undefined;
}

export const VacationFields: React.FC<VacationFieldsProps> = ({
  control,
  date_ini,
  workingDays,
  workingDaysError,
}) => {
  return (
    <>
      <div className="row p-0 input-gap">
        <div className="col-12 col-sm p-0 mr-2">
          <DateInput
            label="FECHA INICIO"
            name="date_ini"
            control={control}
            rules={{ required: "Este campo es requerido" }}
          />
        </div>
        <div className="col-12 col-sm p-0 ml-2">
          <DateInput
            label="FECHA TERMINO"
            name="date_fin"
            control={control}
            rules={{ required: "Este campo es requerido" }}
            minDate={date_ini instanceof Date ? date_ini : undefined}
            disabled={!date_ini}
          />
        </div>
      </div>
      <div className="row">
        <div className="col p-0">
          <TextInput
            label="Días Hábiles"
            name="workingDays"
            value={workingDays}
            disabled
            errorText={workingDaysError}
          />
        </div>
      </div>
    </>
  );
};
